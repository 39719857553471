.navbar {
  position: fixed !important;
  .nextui-c-yrlUe-kJGdUE-activeColor-default {
    --nextui--navbarItemHighlightTextColor: #91c404;
  }
  background: none;
  .nextui-c-dAPDu-blLvbL-isHighlightVariant-true {
    background: #eef7d6;
  }
}
